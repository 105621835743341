import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RichText } from 'prismic-reactjs';
import { graphql } from "gatsby"
import Img from "gatsby-image"

const Faqs = (data) => {
  
  console.log(data);
  let faqs = data.data.prismic.allFaqss.edges[0].node;
  console.log(faqs); 
  return (
    <Layout>
      <SEO 
        title="Questions fréquentes - Virginia Garrido Millan"
        description="Questions frequentes sur les tatouages et son entretien "
        author="Virginia"
      />
      <div className="l-faq">
        <p className="l-faq__title">Hola !</p>
        <div className="container">
            <div className="l-faq__content">
                <h1 className="l-faq__content__title">Questions fréquentes</h1>
                <div className="l-faq__content__wrapper">
                  <div class="l-faq__content__box">
                    <p className="l-faq__content__subtitle">Avant la séance</p>
                    {faqs.avant_seance.map( faq => {
                      return (
                        <div className="l-faq__content__item">
                          <div className="l-faq__content__item__label">
                          {RichText.render(faq.question)}</div>
                          <div className="l-faq__content__item__text">{RichText.render(faq.reponse)}</div>
                      </div>
                      )
                    })}
                  </div>
                  <div class="l-faq__content__box">
                    <p className="l-faq__content__subtitle">Après la séance</p>
                    {faqs.apres_seance.map( faq => {
                      return (
                        <div className="l-faq__content__item">
                          <div className="l-faq__content__item__label"> {RichText.render(faq.question)}</div>
                          <div className="l-faq__content__item__text">{RichText.render(faq.reponse)}</div>
                      </div>
                      )
                    })}
                  </div>
                   
                   
                </div>
            </div>
            <div className="l-faq__picture">
                <Img fluid={data.data.virginia.childImageSharp.fluid} />
            </div>
        </div>
        
      </div>
    </Layout>
  )
}
  
export default Faqs
 
 
export const query = graphql`
  query {
    virginia:file(relativePath: { eq: "apropos/virginia-faq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    
    prismic {
      allFaqss {
        edges {
          node {
            _linkType
            avant_seance {
              question
              reponse
            }
            apres_seance {
              question
              reponse
            }
          }
        }
      }
    }
    
  },
`  


